import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../components/component.header";
import step1 from "../assets/images/step-1.svg";
import BayListOptions from "../components/component.bay-list-options";

import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';

const Bay = ({ session, setSession }) => {
    const history = useHistory();
    const [bayNumber, setBayNumber] = useState('');

    const storeBayNumber = async () => {
        if (!bayNumber || bayNumber === "0") {
            toast.info(i18next.t("containers.bay.please_select"));
        } else {
            await setSession({...session, bay_number: bayNumber });
            history.push('/dashboard');
        }
    }

    return (
        <>
            <Header withLogout={false}
                    heading={i18next.t("containers.bay.title")}
                    description={i18next.t("containers.bay.body")}
                    image={step1}
                    step="1"
                    session={session}
                    setSession={setSession}
                    withBackButton
            />
            <div className="form bay">
                <div className="form__element">
                    <select
                        name="bay"
                        onChange={(event) => setBayNumber(event.target.value)}>
                        <option key="enter-a-value" value="0">{i18next.t("containers.bay.bay_number")}</option>
                        <BayListOptions />
                    </select>
                </div>
                <button className="btn" onClick={() => storeBayNumber()}>{i18next.t("containers.bay.submit")}</button>
            </div>
        </>

    );
}

export default (withTranslation()(Bay));
