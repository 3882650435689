import React  from 'react';
import { useHistory } from "react-router-dom";

import Header from "../components/component.header";
import step3 from "../assets/images/step-3.svg";

import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';

const Staff = ({ session, setSession }) => {
    const history = useHistory();

    const notifyStaff = async () => {
        await setSession({...session, staff: true });
        history.push('/dashboard');
    }

    return (
        <>
            <Header withLogout={false}
                    withBackButton
                    heading={i18next.t("containers.staff.title")}
                    description={i18next.t("containers.staff.body")}
                    image={step3}
                    step="3"
                    session={session}
                    setSession={setSession}
            />
            <div className="staff__options">
                <button className="btn btn-primary" onClick={() => notifyStaff()}>{i18next.t("containers.staff.done")}</button>
            </div>
        </>

    );
}

export default (withTranslation()(Staff));
