import React  from 'react';
import { useHistory } from "react-router-dom";

import Header from "../components/component.header";
import { Reset } from "../helpers/session";

import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';

const Success = ({ location, session, setSession }) => {
    const history = useHistory();

    const returnToDashboard = async () => {
        await Reset({ session, setSession });
        history.push('/dashboard');
    }

    return (
        <>
            <Header
                withLogout={false}
                session={session}
                setSession={setSession}
            />
            <div className="success">
                <div className="success__icon">
                    <i className="far fa-check-circle"></i>
                </div>
                <div className="success__heading">{i18next.t("containers.success.title")}</div>
                {location && location.state && location.state.type === 'hire' && (
                    <>
                        <div className="success__description">{i18next.t("containers.success.hire_body_1")}</div>
                        <div className="success__description">{i18next.t("containers.success.hire_body_2")}</div>
                    </>
                )}
                {location && location.state && location.state.type === 'return' && (
                    <>
                        <div className="success__description">{i18next.t("containers.success.return_body_1")}</div>
                        <div className="success__description">{i18next.t("containers.success.return_body_2")}</div>
                    </>
                )}
                <div className="success__button">
                    <button className="btn" onClick={() => returnToDashboard()}>{i18next.t("containers.error.return")}</button>
                </div>
            </div>
        </>

    );
}


export default (withTranslation()(Success));