import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

// import setupProxy from "./setupProxy";

import './App.scss';
import PrivateRoute from "./components/component.private-route";
import 'react-toastify/dist/ReactToastify.min.css';
import Login from "./containers/login";
import Bay from "./containers/bay";
import QrCode from "./containers/qr-code";
import Rfid from "./containers/rfid";
import Success from "./containers/success";
import Error from "./containers/error";
import Dashboard from './containers/dashboard';
import Staff from "./containers/staff";
import BayStatus from './containers/bay-status';

function App() {
  return (
      <Router>
        <div className="container">
          <Switch>
            <PrivateRoute component={Dashboard} path="/dashboard" />
            <PrivateRoute component={Bay} path="/bay" />
            <PrivateRoute component={QrCode} path="/qr-code" />
            <PrivateRoute component={Rfid} path="/rfid" />
            <PrivateRoute component={Success} path="/success" />
            <PrivateRoute component={Error} path="/error" />
            <PrivateRoute component={Staff} path="/staff" />
            <PrivateRoute component={BayStatus} path="/bay-status" />
            <Route component={Login} path="/login" />
            <Route path="*">
              <Redirect to='/login' />
            </Route>
          </Switch>
          <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
          ></ToastContainer>
        </div>
      </Router>
  );
}

export default App;
