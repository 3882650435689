import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { geolocated, geoPropTypes } from "react-geolocated";



import useStateWithLocalStorage from "../hooks/hook.useStateWithLocalStorage";
import useMakeRequest from "../hooks/hook.useMakeRequest";
import Header from "../components/component.header";
import Loader from "../components/component.loader";
import logo from "../assets/images/brompton.png";

import i18n from '../components/component.i18n';
import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';

const Login = ({ isGeolocationEnabled, isGeolocationAvailable, coords }) => {
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [session, setSession] = useStateWithLocalStorage('session');
    const [submit, setSubmit] = useState(false);
    const [request] = useMakeRequest({
        path: '/login',
        data: {
            portal_password: password,
            latitude: coords !== null && coords.latitude !== undefined ? coords.latitude  : '',
            longitude:  coords !== null && coords.longitude !== undefined ? coords.longitude  : '',
        },
        submit
    });

    const getLocale = () => {         
            if(localStorage.getItem("locale") === null) {
                const languageCode = navigator.language.split("-");
                // Safari can return all lowercase, so standardise
                const localeCode = languageCode[0]+"_"+(languageCode[0].toUpperCase());
                i18n.changeLanguage(localeCode);
            } else {
                i18n.changeLanguage(localStorage.getItem("locale"));
            }
            
    }

    const login = async () => {
        if (!isGeolocationAvailable) {
            return toast.error(i18next.t("containers.login.error_geolocation"));
        }

        if (!isGeolocationEnabled) {
            return toast.error(i18next.t("containers.login.error_not_enabled"));
        }

        if (coords === null) {
            return toast.info(i18next.t("containers.login.info_loading"));
        }

        // valid input
        if (!password) {
            return toast.info(i18next.t("containers.login.error_password"));
        } else {
            // trigger the request
            await setSubmit(true);
        }
    }

    const enterLogin = async (e) => {
        e.preventDefault();
        return login();
    }

    const isLoading = () => {
        const { loading } = request;
        return loading;
    }

    useEffect(() =>  {
        const saveAndRedirect = async () => {
            const { data } = request.response;
            const { latitude, longitude } = coords;
            await setSession({...data, ...{ latitude, longitude }});
            history.push('/dashboard');
        }

        const showError = async () => {
            setPassword('');
            toast.error(request.response.errors[0]);
        }

        if (request.success) {
            if (request.response && request.response.success) {
                saveAndRedirect();
            } else {
               showError();
            }
        }
        setSubmit(false);
    }, [request]);

    // reset the session on page load
    useEffect(() =>  {
        getLocale();
        return setSession({});
    }, []);

    return (
        <>
            <Header transparent withLogout={false} setSession={setSession} />
            <div className="login__background">
                <img className="login__logo" src={logo} alt={i18next.t("containers.login.logo_alt")} />
            </div>
            <form className="form login" onSubmit={(e) => enterLogin(e)}>
                <Loader isLoading={isLoading()} />
                <h1 className="login__title">{i18next.t("containers.login.title")}</h1>
                <p className="login__text login__text--mb-4">{i18next.t("containers.login.body")}</p>
                <div className="form__element">
                    <input type={showPassword ? 'text' : 'password'}
                           name="password"
                           placeholder={i18next.t("containers.login.password")}
                           value={password}
                           onChange={(event) => setPassword(event.target.value)}/>
                    <span className="form__toggle-password" onClick={() => setShowPassword(!showPassword)}>{showPassword ? i18next.t("containers.login.hide") : i18next.t("containers.login.show") }</span>
                </div>
                <p className="login__text login__text--small login__text--left">{i18next.t("containers.login.forgot_password")}</p>
                <button type="button"  className="btn" onClick={() => login()}>{i18next.t("containers.login.log_in")}</button>
            </form>
        </>

    );
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(withTranslation()(Login));