import i18n from "i18next";
//import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

const resources = require('../locales/translations.json');
const languages = Object.keys(resources);

const options = {
  order: ['navigator'], 
  lookupQuerystring: 'lng',
  lookupFromPathIndex: 0
}

i18n
  //.use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    compatibilityJSON: 'v3',
    detection: options,
    fallbackLng: 'en_GB',
    supportedLngs: languages,
    whitelist: languages,
    interpolation: {
      escapeValue: false // react already safe from xss
    },
    react: {
      useSuspense:false,
      bindI18n: 'languageChanged',
    }
  });

export default i18n;