import React, { useEffect, useState } from 'react';

import Header from "../components/component.header";
import useMakeRequest from "../hooks/hook.useMakeRequest";
import useInterval from "../hooks/hook.useInterval";
import reservedNoBike from "../assets/images/reserved-bay-no-bike.svg";
import reservedWithBike from "../assets/images/reserved-bay-with-bike.svg";
import unreservedNoBike from "../assets/images/unreserved-bay-no-bike.svg";
import unreservedWithBike from "../assets/images/unreserved-bay-with-bike.svg";
import disabledNoBike from "../assets/images/disabled-no-bike.svg";
import disabledWithBike from "../assets/images/disabled-with-bike.svg";

import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';

const BayStatus = ({ session, setSession }) => {
    const [filter, setFilter] = useState('all');
    const [submit, setSubmit] = useState(false);
    const [bays, setBays] = useState([]);
    const [originalBays] = useState(session.bays);
    const [visibleBays, setVisibleBays] = useState([]);
    const [delay, setDelay] = useState(null);
    const [changes, setChanges] = useState([]);

    const [request] = useMakeRequest({
        path: '/returnDockBaysStatus',
        data: {
            include_additional: 1,
        },
        submit
    });

    useInterval(() => {
        setSubmit(true);
    }, delay);

    // add is_new to the current bays object
    useEffect(() => {
        // compare the original bays (stored in session) with the latest request
        for (const [key, bay] of Object.entries(bays)) {
            const isNew = ((originalBays.hasOwnProperty(key) && originalBays[key].reservation_status !== bay.reservation_status)
                || (changes[key] && changes[key] !== bay.reservation_status));

            if (isNew) {
                // set the type of change on the bay
                bays[key].is_new = isNew && bay.bay_status === 'reserved' ? 'new' : 'cancelled';
                // store the update in the changes array for later comparision
                if (bays[key].reservation_status !== "") {
                    changes[key] = bays[key].reservation_status;
                    setChanges(changes);
                }
            } else {
                bays[key].is_new = false;
            }
        }
    }, [bays]);

    // check for API response statuses
    useEffect(() => {
        if (request.success && request.response && request.response.success) {
            const { data } = request.response;
            // reset the bay session values
            setSession({...session, bays: [] });
            setBays(Object.values(data));
        }
        setSubmit(false);
    }, [request]);

    // check for API response statuses
    useEffect(() => {
        setSubmit(true);
        setDelay(3000);
    }, []);

    // sort the bays on bay load and filter change
    useEffect(() => {
        const sortBays = async () => {
            if (filter === 'reserved') {
                const filteredBays = bays.filter((bay) => {
                    return bay.bay_status !== "free" && bay.bay_status !== "tentative";
                })
                await setVisibleBays(filteredBays);
            } else {
                await setVisibleBays(bays);
            }
        }
        sortBays();
    }, [filter, bays]);

    return (
        <>
            <Header withLogout={false}
                    withBackButton
                    title={i18next.t("containers.bayStatus.title")}
                    session={session}
                    setSession={setSession}
                    timeoutMinutesLength={1}
            />
            <div className="bay-status">
                <div className="bay-status__filters">
                    <div className={filter === 'all' ? 'bay-status__option bay-status__option--selected' : 'bay-status__option'}
                         onClick={() => setFilter('all')}
                    >
                        {i18next.t("containers.bayStatus.filter_all")}
                    </div>
                    <div className={filter === 'reserved' ? 'bay-status__option bay-status__option--selected' : 'bay-status__option'}
                         onClick={() => setFilter('reserved')}
                     >
                        {i18next.t("containers.bayStatus.filter_reserved")}
                    </div>
                </div>
                <div className="bay-status__reservations">
                    {filter === 'reserved' && visibleBays.length === 0 && (
                        <div>{i18next.t("containers.bayStatus.none_reserved")}</div>
                    )}
                    {visibleBays.map((bay) => {
                        let bayStatus = bay.bay_status === 'reserved' ? 'Reserved' : 'Free';
                        if (bay.status === "disabled") {
                            bayStatus = 'DISABLED';
                        }
                        return (
                            <div key={bay.bay_number} className={bay.status === "enabled" ? "bay-status__bay" : 'bay-status__bay bay-status__bay--disabled'}>
                                <div>
                                    {bay.status === "disabled" && bay.bike_name !== "" && (
                                        <img className="bay-status__image" src={disabledWithBike} alt={i18next.t("containers.bayStatus.alt_disabled_bike")} />
                                    )}
                                    {bay.status === "disabled" && bay.bike_name === "" && (
                                        <img className="bay-status__image" src={disabledNoBike} alt={i18next.t("containers.bayStatus.alt_disabled_no_bike")} />
                                    )}
                                    {bay.status === "enabled" && bay.reservation_status === "pending_collection" && (
                                        <img className="bay-status__image" src={reservedWithBike} alt={i18next.t("containers.bayStatus.alt_pending_collection")} />
                                    )}
                                    {bay.status === "enabled" && bay.reservation_status === "pending_return" && (
                                        <img className="bay-status__image" src={reservedNoBike} alt={i18next.t("containers.bayStatus.alt_pending_return")} />
                                    )}
                                    {bay.status === "enabled" && bay.reservation_status === "" && bay.bike_name !== "" && (
                                        <img className="bay-status__image" src={unreservedWithBike} alt={i18next.t("containers.bayStatus.alt_await_hire")} />
                                    )}
                                    {bay.status === "enabled" && bay.reservation_status === "" && bay.bike_name === "" && (
                                        <img className="bay-status__image" src={unreservedNoBike} alt={i18next.t("containers.bayStatus.alt_await_return")} />
                                    )}
                                </div>
                                <div>
                                    <div className="bay-status__number">
                                        <span>{i18next.t("containers.bayStatus.bay")} {bay.bay_number}</span>
                                        {bay.is_new && (<span className="bay-status__new">{bay.is_new}</span>)}
                                        {bay.reservation_status === "pending_collection" && bay.contents === "bike" && (
                                            <span className="bay-status__arrow"><i className="far fa-long-arrow-right"></i></span>
                                        )}
                                        {bay.reservation_status === "pending_return" && bay.contents === "empty" && (
                                            <span className="bay-status__arrow"><i className="far fa-long-arrow-left"></i></span>
                                        )}
                                        {bay.bike_status !== "" && bay.bike_status !== "active" && (
                                            <span className="bay-status__bike-status"><i className="far fa-exclamation-circle"></i></span>
                                        )}
                                    </div>
                                    <div className="bay-status__info">
                                        {i18next.t("containers.bayStatus.status")}: <span className="bay-status__value">{bayStatus}</span>
                                    </div>
                                    {bay.members_name && (
                                        <div className="bay-status__info">
                                            {i18next.t("containers.bayStatus.user")}: <span className="bay-status__value">{bay.members_name}</span>
                                        </div>
                                    )}
                                    <div className="bay-status__info">
                                        {i18next.t("containers.bayStatus.contents")}: <span className="bay-status__value">{bay.bike_name !== '' ? bay.bike_name : 'Empty'}</span>
                                    </div>
                                    {((bay.status !== "" && bay.status !== "enabled") || (bay.bike_status === 'flagged' || bay.bike_status === 'stolen'))  && (
                                        <>
                                            <div className="bay-status__info">
                                                {i18next.t("containers.bayStatus.bike_note")}: <span className="bay-status__value">{bay.bike_notes !== "" ? bay.bike_notes : i18next.t("containers.bayStatus.bike_note_no_reason")}</span>
                                            </div>
                                        </>
                                    )}
                                </div>

                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default (withTranslation()(BayStatus));