import React from 'react';
import { useHistory } from "react-router-dom";

import Header from "../components/component.header";
import logo from "../assets/images/brompton.png";
import ListItem from "../components/component.list-item";
import { Reset  } from '../helpers/session';

import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';

const Dashboard = ({ session, setSession }) => {
    const history = useHistory();

    const isBayComplete = () => {
        const { bay_number } = session;
        return bay_number !== undefined;
    }

    const isScanComplete = () => {
        const { qrcode_status } = session;
        return qrcode_status !== undefined && qrcode_status === true;
    }

    const isStaffComplete = () => {
        const { staff } = session;
        return staff !== undefined && staff === true;
    }

    const isComplete = () => {
        return isBayComplete() && isScanComplete() && isStaffComplete();
    }

    return (
        <>
            <Header
                withLogout={false}
                heading={i18next.t("containers.dashboard.title")}
                description={i18next.t("containers.dashboard.body")}
                image={logo}
                session={session}
                setSession={setSession}
            />
            <div className="dashboard__list">
                <ListItem title={session.bay_number ? i18next.t("containers.dashboard.selected_bay") : i18next.t("containers.dashboard.select_bay")} bayNumber={session.bay_number} showChecked={isBayComplete()} showArrow={!isBayComplete()} onClick={() => isBayComplete() ? null : history.push('/bay')} />
                <ListItem title={i18next.t("containers.dashboard.scan_code")} showChecked={isScanComplete()} showArrow={!isScanComplete()} onClick={() => !isBayComplete() || isScanComplete() ? null : history.push('/qr-code')} />
                <ListItem title={i18next.t("containers.dashboard.notify_staff")} showChecked={isStaffComplete()} showArrow={!isStaffComplete()} onClick={() => !isBayComplete() || !isScanComplete() || isStaffComplete() ? null : history.push('/staff')} />
            </div>
            <div className="dashboard__options">
                {isComplete() && (<button className="btn" onClick={() => history.push('/rfid')}>{i18next.t("containers.dashboard.proceed")}</button>)}
                <button className="btn" onClick={() => Reset({ session, setSession })}>{i18next.t("containers.dashboard.start_over")}</button>
            </div>
        </>

    );
}

export default (withTranslation()(Dashboard));
