import React from 'react';
import { useHistory } from "react-router-dom";

import Header from "../components/component.header";
import { Reset } from "../helpers/session";

import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';

const Error = ({ location, session, setSession }) => {
    const history = useHistory();

    const returnToDashboard = async () => {
        await Reset({ session, setSession });
        history.push('/dashboard');
    }

    return (
        <>
            <Header withLogout={false} session={session} setSession={setSession}  />
            <div className="error">
                <div className="error__icon">
                    <i className="far fa-times-circle"></i>
                </div>
                {!location && <div className="error__heading">{i18next.t("containers.error.title")}</div>}
                {location && location.state && location.state.heading && (<div className="error__heading">{location.state.heading}</div>)}
                {location && location.state && location.state.description && (<div className="error__description">{location.state.description}</div>)}
                <div className="error__button">
                    <button className="btn" onClick={() => returnToDashboard()}>{i18next.t("containers.error.return")}</button>
                </div>
            </div>
        </>

    );
}

export default (withTranslation()(Error));