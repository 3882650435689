import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import useMakeRequest from "../hooks/hook.useMakeRequest";
import Header from "../components/component.header";
import useInterval from "../hooks/hook.useInterval";
import Loader from "../components/component.loader";
import step2 from "../assets/images/step-2.svg";

import i18next, { t } from 'i18next';
import { withTranslation } from 'react-i18next';

const QrCode = ({ session, setSession }) => {
    const history = useHistory();
    const [delay, setDelay] = useState(null);
    const [qrCodeSubmit, setQrCodeSubmit] = useState(false);
    const [qrCodeStatusSubmit, setQrCodeStatusSubmit] = useState(false);
    const [qrCodeImage, setQrCodeImage] = useState("");
    const [error, setError] = useState(false);

    useInterval(() => {
        setQrCodeStatusSubmit(true);
    }, delay);

    const [qrCodeRequest] = useMakeRequest({
        path: '/returnQRCode',
        data: {
            bay_number: session.bay_number,
        },
        submit: qrCodeSubmit
    });

    const [qrcodeStatusRequest] = useMakeRequest({
        path: '/returnQRStatus',
        data: {
            bay_number: session.bay_number,
        },
        submit: qrCodeStatusSubmit
    });

    const isLoading = () => {
        const { loading } = qrCodeRequest;
        return loading;
    }

    // Get QR Code
    useEffect(() => {
        const errorAndRedirect = async ({ description }) => {
            const heading = i18next.t("containers.qrCode.error_title");
            const error = description !== undefined ? description : i18next.t("containers.qrCode.error_body");
            history.push('/error', { heading, description: error });
        }

        if (qrCodeRequest.success && qrCodeRequest.response && qrCodeRequest.response.data) {
            const { base64_string } = qrCodeRequest.response.data;
            setQrCodeImage(base64_string);
        }
        setQrCodeSubmit(false);

        if (qrCodeRequest.success && qrCodeRequest.response && !qrCodeRequest.response.success && qrCodeRequest.response.errors) {
            errorAndRedirect({ description: qrCodeRequest.response.errors[0] });
        }
    }, [qrCodeRequest]);

    // check QR Code Status
    useEffect(() => {
        const successAndRedirect = async () => {
            toast.success(i18next.t("containers.qrCode.validated"));
            await setSession({...session, qrcode_status: true });
            history.push('/dashboard');
        }

        const errorAndRedirect = async ({ description }) => {
            const error = description !== undefined ? description : i18next.t("containers.qrCode.check_message");
            setError(error);
        }

        if (qrcodeStatusRequest.success && qrcodeStatusRequest.response && qrcodeStatusRequest.response.data) {
            const { qr_code_status } =  qrcodeStatusRequest.response.data;
            if (qr_code_status === 1) {
                successAndRedirect();
            } else if (qr_code_status === 2) {
                errorAndRedirect({ description: i18next.t("containers.qrCode.verification_failed") });
            }
        }

        setQrCodeStatusSubmit(false);
    }, [qrcodeStatusRequest]);

    // on page load
    useEffect(() => {
        setQrCodeSubmit(true);
        setDelay(3000);
    }, []);

    return (
        <>
            <Header withLogout={false}
                    withBackButton
                    heading={i18next.t("containers.qrCode.title")}
                    description={i18next.t("containers.qrCode.body")}
                    image={step2}
                    step="2"
                    session={session}
                    setSession={setSession}
            />
            <div className="form">
                <div className="qr-code__image">
                    <Loader isLoading={isLoading()}/>
                    {qrCodeImage !== "" && <img src={qrCodeImage} alt={i18next.t("containers.qrCode.qr_code")} />}
                </div>
                {!error && (<p className="qr-code__text">{i18next.t("containers.qrCode.update_status")}</p>)}
                {error && (
                    <div className="qr-code__error">
                        <div className="qr-code__error-icon">
                            <i className="far fa-times-circle"></i>
                        </div>
                        <div className="qr-code__error-description">{error}.</div>
                    </div>

                )}
            </div>
        </>

    );
}

export default (withTranslation()(QrCode));